import React from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'

import ConnectPage from './_components/ConnectPage'

const Index = ({ data: { props } }) => {
	props.meta.canonical = `${process.env.GATSBY_SITE_HOST}/${props.slug}/`

	return <ConnectPage {...props} />
}

Index.propTypes = { data: object, pageContext: object }

export default Index

export const GatsbyQuery = graphql`
	{
		props: pageModel(slug: "connect") {
			slug
			meta {
				robots
				description
				title
			}
			... on ConnectPage {
				header {
					cta
					title
					subtitle
					hero
				}
				applicationForm {
					confirmationMessage {
						subTitle
						title
						description
					}
					subTitle
					title
				}
				hasVwo
				offerings {
					sectionTitle
					cta
					listOfferings {
						bulletPoints
						description
						title
						title
						url
					}
				}
				testimonials {
					title
					imgUrl
					listTestimonials {
						clientName
						industry
						context
					}
				}
				relatedArticles {
					canonical
				}
			}
		}
	}
`
